const environment = {
  production: true,
  development: false,
  environmentName: 'production',
  siteUrl: 'https://wedzee.com/',
  shippo: {
    apiKey: '',
    carrier: '',
    tracking_number: ''
  },
  firebase: {
      config: {
          apiKey: 'AIzaSyD2gqd5K0Xe11VQFfehahbiv4BYJpxzO4c',
          authDomain: 'Wedzee.com',
          databaseURL: 'https://wedzee-prod.firebaseio.com',
          projectId: 'wedzee-prod',
          storageBucket: 'wedzee-prod.appspot.com',
          messagingSenderId: '597971778871'
        }
  },
  recaptchaSiteKey: '6LcXzHMUAAAAAPNi_HhmggOGgI3aLOsTvV573v4g',
  functionsUrl: 'https://us-central1-wedzee-prod.cloudfunctions.net',
  apiUrl: 'https://wedzee-prod.appspot.com/api/',
  supportEmail: 'info@wedzee.com',
  supportPhone: '(863) 606-3927',
  streetAddress: {
    street1: '1002B S. Church Ave. 320791',
    city: 'Tampa',
    state: 'FL',
    zip: '33629'
  },
  stripe: {
    apiKey: 'pk_live_QYIodBEBDhKvwU9n9pFO8GTD',
    clientId: 'ca_DJANHD1LEHmEhZPNLtHE8EmNtJHovmsR',
    redirectUris: {
      sellerAccountCreated: 'https://wedzee.com/seller/home?newRegistration=true',
      existingSellerRegistered: 'https://wedzee.com/seller/stripe-registered'
    }
  },
  algolia: {
    appId: 'PRMKC36TKH',
    publicKey: '8399fdc77c8043e2eca0f75ebc588864'
  },
  googlePlaces: {
    apiKey: 'AIzaSyD2gqd5K0Xe11VQFfehahbiv4BYJpxzO4c'
  },
  paypal: {
    sandboxClientId: 'AY_lbuA8fzyh5RbKqqfsMBxkgWXOieyHYr-UORKUsPoUF63r9bl-gl5JojSu_hP0fwFUuzYDeuJeBmsO',
    productionClientId: 'AcHUtrBx9xG8ti6AuxfJ4oT8JeJKBeycRPuhENmC-1MUcfFlerFGUsHxs2cx9z-aj7MPwsFNSARsnipb'
  },
  googleAnalytics: {
    trackingId: 'UA-97414347-3'
  },
  socialUrls: {
    facebook: 'https://www.facebook.com/Wedzee-136828397271779',
    twitter: 'https://twitter.com/shopwedzee',
    instagram: 'https://www.instagram.com/shopwedzee',
    pinterest: 'https://www.pinterest.com/161jc1eqpd5andg0u8g5c8d3fyl6l1'
  },
  taxjarApi: {
    version: '2022-01-24'
  }
};

export default environment;
